<template>
  <div id="Dashboard">

  </div>
</template>
<script>
export default {
  name: 'Dashboard',
  data () {
    return {

    }
  },
  beforeMount () {
  },
  methods: {

  }
}
</script>
