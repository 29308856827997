<template>
  <div class="about">
    <div id="displayDash" v-if="connected" class=" fixed top-0 left-0 z-50 bg-white" style="width:100vw; height:100vh; z-index:1000;">

      <div class="w-full h-full flexRow">
        <div class="w-1/3 bg-blue-100">
          <section class="text-blueGray-700 w-full">
            <div class="container items-center py-12 lg:px-20 ">
              <div class="flex flex-col w-full p-10 mx-auto my-6 transition duration-500 ease-in-out transform bg-white border rounded-lg lg:w-full lg:h-full md:w-1/2 md:mt-0" style="min-height: 90vh;">
                <div class="relative mt-4">
                  <ul class="text-base leading-7 text-blueGray-500">
                    <li class="cursor-pointer" :id="cat" v-for="(cat, index) in catList" :key="index"> {{ cat }} </li>
                  </ul>
                  <input type="text" id="cat" name="cat" v-model="cat" placeholder="Catégorie" class="w-full px-4 py-2 mt-2 text-base text-black transition duration-500 ease-in-out transform border-transparent rounded-lg bg-blueGray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2">
                </div>
                <button @click="addCat()" class="w-full px-16 py-2 my-2 mr-2 text-base text-white transition duration-500 ease-in-out transform bg-blue-600 border-blue-600 rounded-md focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:bg-blue-800 "> Ajouter </button>
                <p class="mx-auto mt-3 text-xs text-blueGray-500"> Nouvelle catégorie</p>
              </div>
            </div>
          </section>

        </div>
        <div class="w-1/3 bg-blue-200">
          <section class="text-blueGray-700 w-full">
            <div class="container items-center py-12 lg:px-20 ">
              <div class="flex flex-col w-full p-10 mx-auto my-6 transition duration-500 ease-in-out transform bg-white border rounded-lg lg:w-full lg:h-full md:w-1/2 md:mt-0" style="min-height: 90vh;">
                <div class="relative mt-4">
                  <ul class="text-base leading-7 text-blueGray-500">
                    <li class="cursor-pointer" v-for="(cat, index) in catList" :key="index"> {{ cat }} </li>
                  </ul>
                  <input type="text" id="" name="produit" v-model="cat" placeholder="Catégorie" class="w-full px-4 py-2 mt-2 text-base text-black transition duration-500 ease-in-out transform border-transparent rounded-lg bg-blueGray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2">
                </div>
                <button @click="addCat()" class="w-full px-16 py-2 my-2 mr-2 text-base text-white transition duration-500 ease-in-out transform bg-blue-600 border-blue-600 rounded-md focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:bg-blue-800 "> Ajouter </button>
                <p class="mx-auto mt-3 text-xs text-blueGray-500"> Nouvelle catégorie</p>
              </div>
            </div>
          </section>
        </div>
        <div class="w-1/3 bg-blue-300"></div>
      </div>

    </div>

    <div id="displayForm">
      <section class="flex flex-col items-center h-screen md:flex-row ">
        <div class="hidden w-full h-screen bg-white lg:block md:w-1/3 lg:w-2/3">
          <img src="" alt="" class="object-cover w-full h-full">
        </div>
        <div class="flex items-center justify-center w-full h-screen px-6 bg-white md:max-w-md lg:max-w-full md:mx-auto md:w-1/2 xl:w-1/3 lg:px-16 xl:px-12">
          <div class="w-full h-100">
            <a class="flex items-center w-64 mb-4 font-medium text-blueGray-900 title-font md:mb-0">
              <div class="w-2 h-2 p-2 mr-2 rounded-full bg-gradient-to-tr from-blue-300 to-blue-600">
              </div>
              <h2 class="text-lg font-bold tracking-tighter text-black uppercase duration-500 ease-in-out transform ttransition hover:text-lightBlue-500 dark:text-blueGray-400"> 3 drôles de mecs </h2>
            </a>
            <h1 class="mt-12 text-2xl font-semibold text-black tracking-ringtighter sm:text-3xl title-font">Connexion administrateur</h1>

            <div>
              <label class="block text-sm font-medium leading-relaxed tracking-tighter text-blueGray-700">Login</label>
              <input type="text" v-model="log" name="" id="log" placeholder="Votre login " class="w-full px-4 py-2 mt-2 text-base text-black transition duration-500 ease-in-out transform border-transparent rounded-lg bg-blueGray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ">
            </div>
            <div class="mt-4">
              <label class="block text-sm font-medium leading-relaxed tracking-tighter text-blueGray-700">Mot de passe</label>
              <input type="password" v-model="pass" name="" id="pass" placeholder="Votre mot de passe" minlength="6" class="w-full px-4 py-2 text-base text-black transition duration-500 ease-in-out transform border-transparent rounded-lg bg-blueGray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 " required="">
            </div>
            <button @click="connexion" class="block w-full px-4 py-3 mt-6 font-semibold text-white transition duration-500 ease-in-out transform bg-black rounded-lg hover:bg-blueGray-800 focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 ">Connexion</button>

            <hr class="w-full my-6 border-blueGray-300">

          </div>
        </div>
      </section>

      <div id="alert"  class="container items-center px-5 py-12 text-blueGray-500 lg:px-20 absolute top-1/2 right-1/4 hidden">
        <div class="p-2 mx-auto my-6 bg-white border rounded-lg shadow-xl lg:w-1/2">
          <div class="flex-grow p-6 py-2 rounded-lg">
            <div class="inline-flex items-center w-full ">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 icon icon-tabler icon-tabler-alert-triangle" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="9"></circle>
                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                <polyline points="11 12 12 12 12 16 13 16"></polyline>
              </svg>
              <strong class="text-blue-800"> Note</strong>
            </div>
            <p id="text" class="my-4 text-sm font-semibold tracking-wide uppercase"> Nom d'utilisateur invalide !  </p>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import jQuery from 'jquery'
import db from '../firestore'
const $ = jQuery
export default {
  name: 'About',
  data () {
    return {
      loginAdmin: 'Julien',
      passAdmin: 'Lavoisine',
      log: '',
      pass: '',
      cat: '',
      connected: false,
      catList: []

    }
  },
  beforeCreate () {
    db.collection('categories').get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.catList.push(doc.id)
        console.log(this.catList)
      })
    })
  },
  methods: {
    addCat () {
      const cat = this.cat
      db.collection('categories').doc(cat).set({})
    },
    addProduct () {

    },
    selectedCat (e) {
      this.selectedCat = e.target.id
    },
    connexion () {
      const log = $('#log').val()
      const pass = $('#pass').val()
      if (log === this.loginAdmin) {
        if (pass === this.passAdmin) {
          this.connected = true
        } else {
          setTimeout(() => {
            $('#text').html('Mot de passe invalide !')
            $('#alert').removeClass('hidden')
            $('#displayForm').hide()
            $('#displayDash').show()
          }, 200)
          setTimeout(() => {
            $('#text').html("Nom d'utilisateur invalide !")
            $('#alert').addClass('hidden')
          }, 2500)
        }
      } else {
        setTimeout(() => {
          $('#alert').removeClass('hidden')
        }, 200)
        setTimeout(() => {
          $('#alert').addClass('hidden')
        }, 2500)
      }
    }
  }
}
</script>
