// /src/firestore.js
import firebase from 'firebase'
import 'firebase/firestore'
const firebaseConfig = {
  apiKey: 'AIzaSyDrkoxJ8n054mUhxJvRWE1iOCEoujs5fto',
  authDomain: 'mangerie3ddm.firebaseapp.com',
  projectId: 'mangerie3ddm',
  storageBucket: 'mangerie3ddm.appspot.com',
  messagingSenderId: '17554373056',
  appId: '1:17554373056:web:9f18dec1f65a1862c27025'
}
const firestore = firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()

export default db
